<template>
    <div class="mainWrapper">


        <SupervisorMainSideBar v-if="windowWidth > mobileWidthThreshold" />

        <div v-else>

            <SupervisorMobileTopNav />

            <SupervisorMobileBottomNav />

        </div>




        <div class="rightContentWrapper"
            :style="windowWidth <= mobileWidthThreshold ? 'width: 100%; padding-top: 40px;' : ''">


            <div class="topNav">
                <TopBarVue v-if="windowWidth > mobileWidthThreshold" />
            </div>



            <div class="innerContainer">

                <div class="theHeaderWrapper">

                    <i @click="$router.go(-1)" style="font-size: 25px; font-weight: bold; cursor: pointer;"
                        class="bi bi-arrow-left"></i>

                    <div style="margin-left: 20px;">



                    </div>

                </div>






                <div class="contentWrapper" v-if="supervisorDashboardStore.post">





                    <div v-if="supervisorDashboardStore.post.message_goal_id == 1" class="talkBox">


                        <div class="textBox">

                            <div class="headerBox">

                                <div class="theTitle">

                                    {{ supervisorDashboardStore.post.title }}
                                </div>

                                <div>

                                    <div class="talkDone"
                                        v-if="supervisorDashboardStore.groupTalkPost.is_acknowledged == 1">

                                        <i v-if="supervisorDashboardStore.groupTalkPost.completion_type === 'checkoff'"
                                            class="bi bi-people"
                                            @click="() => { supervisorDashboardStore.fetchEmployeesAcknowledgementStatusForCheckoff(); supervisorDashboardStore.openUpdateCheckoffModal(); }"
                                            style="margin-right: 15px; font-size: 24px; cursor: pointer;"></i>


                                        <i v-if="supervisorDashboardStore.groupTalkPost.completion_type === 'signature'"
                                            @click="supervisorDashboardStore.openFetchAckDataAndOpenModal"
                                            class="bi bi-people"
                                            style="margin-right: 15px; font-size: 24px; cursor: pointer;"></i>

                                        <i v-if="supervisorDashboardStore.groupTalkPost.completion_type === 'signed_sheet'"
                                            @click="supervisorDashboardStore.openSigninSheetPreviewModal"
                                            class="bi bi-people"
                                            style="margin-right: 15px; font-size: 24px; cursor: pointer;"></i>

                                        <span class="badge rounded-pill bg-success">
                                            Talk Completed
                                        </span>


                                    </div>
                                    <div v-else>

                                        <button class="button-13"
                                            @click="supervisorDashboardStore.openSignOffModalOption">
                                            <b>Complete</b>
                                        </button>

                                    </div>

                                </div>

                            </div>




                            <div class="textContent">

                                <div class="htmlHtml" v-html="supervisorDashboardStore.post.body"></div>


                            </div>




                        </div>



                    </div>


                    <div class="talkBox" v-if="supervisorDashboardStore.post.message_goal_id == 2">


                        <div class="pdfBox">



                            <div class="headerBox">

                                <div class="theTitle">

                                    {{ supervisorDashboardStore.post.title }}
                                </div>

                                <div>

                                    <div class="talkDone"
                                        v-if="supervisorDashboardStore.groupTalkPost.is_acknowledged == 1">

                                        <i v-if="supervisorDashboardStore.groupTalkPost.completion_type === 'checkoff'"
                                            class="bi bi-people"
                                            @click="() => { supervisorDashboardStore.fetchEmployeesAcknowledgementStatusForCheckoff(); supervisorDashboardStore.openUpdateCheckoffModal(); }"
                                            style="margin-right: 15px; font-size: 24px; cursor: pointer;"></i>


                                        <i v-if="supervisorDashboardStore.groupTalkPost.completion_type === 'signature'"
                                            @click="supervisorDashboardStore.openFetchAckDataAndOpenModal"
                                            class="bi bi-people"
                                            style="margin-right: 15px; font-size: 24px; cursor: pointer;"></i>


                                        <i v-if="supervisorDashboardStore.groupTalkPost.completion_type === 'signed_sheet'"
                                            @click="supervisorDashboardStore.openSigninSheetPreviewModal"
                                            class="bi bi-people"
                                            style="margin-right: 15px; font-size: 24px; cursor: pointer;"></i>


                                    </div>
                                    <div v-else>

                                        <button class="button-13"
                                            @click="supervisorDashboardStore.openSignOffModalOption">
                                            <b>Complete</b>
                                        </button>

                                    </div>

                                </div>

                            </div>


                            <div class="pdfContent">


                                <vue-pdf-embed :source="supervisorDashboardStore.source1" />

                            </div>



                        </div>



                    </div>

                    <div class="talkBox" v-if="supervisorDashboardStore.post.message_goal_id == 3">



                        <div class="videoBox">


                            <div class="headerBox">

                                <div class="theTitle">

                                    {{ supervisorDashboardStore.post.title }}
                                </div>

                                <div>

                                    <div class="talkDone"
                                        v-if="supervisorDashboardStore.groupTalkPost.is_acknowledged == 1">


                                        <i v-if="supervisorDashboardStore.groupTalkPost.completion_type === 'checkoff'"
                                            class="bi bi-people"
                                            @click="() => { supervisorDashboardStore.fetchEmployeesAcknowledgementStatusForCheckoff(); supervisorDashboardStore.openUpdateCheckoffModal(); }"
                                            style="margin-right: 15px; font-size: 24px; cursor: pointer;"></i>


                                        <i v-if="supervisorDashboardStore.groupTalkPost.completion_type === 'signature'"
                                            @click="supervisorDashboardStore.openFetchAckDataAndOpenModal"
                                            class="bi bi-people"
                                            style="margin-right: 15px; font-size: 24px; cursor: pointer;"></i>


                                        <i v-if="supervisorDashboardStore.groupTalkPost.completion_type === 'signed_sheet'"
                                            @click="supervisorDashboardStore.openSigninSheetPreviewModal"
                                            class="bi bi-people"
                                            style="margin-right: 15px; font-size: 24px; cursor: pointer;"></i>



                                        <span class="badge rounded-pill bg-success">
                                            Talk Completed
                                        </span>


                                    </div>
                                    <div v-else>

                                        <button class="button-13"
                                            @click="supervisorDashboardStore.openSignOffModalOption">
                                            <b>Complete</b>
                                        </button>

                                    </div>

                                </div>

                            </div>
                            <div class="videoContent">


                                <div v-if="supervisorDashboardStore.thisVideoIsYoutube">

                                    <div class="yTvideoWrapper" v-html="supervisorDashboardStore.post.video_embed_code">
                                    </div>

                                </div>

                                <div v-else>



                                    <div class="normalVideoWrapper"
                                        v-html="supervisorDashboardStore.post.video_embed_code"></div>

                                </div>






                            </div>




                        </div>


                    </div>

                </div>




            </div>






        </div>


        <div v-if="supervisorDashboardStore.showSignOffModalOption" class="modal-overlay">

            <div class="modal-box">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Select A Method</b>
                    </div>



                    <div>
                        <i @click="supervisorDashboardStore.closeSignOffModalOption" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">



                    <div class="optionItemWrapper">

                        <div v-if="supervisorDashboardStore.sign_off_method === 'all_signoff_methods'">

                            <div v-for="(option, index) in supervisorDashboardStore.optionsArray" :key="index">


                                <div class="optionItem" :class="{
                                    optionItemSelected:
                                        supervisorDashboardStore.currentMethodSelected ===
                                        option.type,
                                }" @click="supervisorDashboardStore.triggerOpenCorrectModal(option.type)">

                                    <div class="theItemHeader">

                                        {{ option.name }}

                                    </div>

                                    <div class="theItemBody">

                                        {{ option.desc }}

                                    </div>

                                </div>



                            </div>

                        </div>

                        <div v-if="supervisorDashboardStore.sign_off_method === 'checkoff'">
 
                            <div class="optionItemSelected" 
                                @click="supervisorDashboardStore.triggerOpenCorrectModal(supervisorDashboardStore.theSignOffMethod.type)">

                                <div class="theItemHeader">

                                    {{ supervisorDashboardStore.theSignOffMethod.name }}

                                </div>

                                <div class="theItemBody">

                                    {{ supervisorDashboardStore.theSignOffMethod.desc }}

                                </div>

                            </div>


                        </div>

                        <div v-if="supervisorDashboardStore.sign_off_method === 'digital_signatures'">

                            <div class="optionItemSelected" 
                                @click="supervisorDashboardStore.triggerOpenCorrectModal(supervisorDashboardStore.theSignOffMethod.type)">

                                <div class="theItemHeader">

                                    {{ supervisorDashboardStore.theSignOffMethod.name }}

                                </div>

                                <div class="theItemBody">

                                    {{ supervisorDashboardStore.theSignOffMethod.desc }}

                                </div>

                            </div>


                        </div>


                        <div v-if="supervisorDashboardStore.sign_off_method === 'photo_of_sheet'">

                            <div class="optionItemSelected" 
                                @click="supervisorDashboardStore.triggerOpenCorrectModal(supervisorDashboardStore.theSignOffMethod.type)">

                                <div class="theItemHeader">

                                    {{ supervisorDashboardStore.theSignOffMethod.name }}

                                </div>

                                <div class="theItemBody">

                                    {{ supervisorDashboardStore.theSignOffMethod.desc }}

                                </div>

                            </div>


                        </div>





                    </div>





                </div>
            </div>
        </div>

        <div v-if="supervisorDashboardStore.showCheckoffEmployeeModal" class="modal-overlay">

            <div class="modal-box" style="height: 100%; padding-top: 100px;">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Checkoff</b>
                    </div>

                    <div>
                        <i @click="supervisorDashboardStore.closeCheckoffModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left; background-color: yellow;">



                    <div class="teamList">


                        <table class="table table-hover my-custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Present</th>

                                </tr>
                            </thead>


                            <tbody>



                                <tr v-for="(employee, index) in supervisorDashboardStore.employeesCheck" :key="index">



                                    <td>
                                        {{ employee.name }}
                                    </td>


                                    <td @click="supervisorDashboardStore.updatePresentStatus(employee)"
                                        style="display: flex; justify-content: center;">


                                        <div v-if="employee.checked" class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                                checked>
                                        </div>

                                        <div v-else class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                        </div>


                                    </td>


                                </tr>

                            </tbody>




                        </table>



                    </div>

                    <div class="btn btn-success theCheckoffMarkBtn" style="width: 100%;"
                        @click="supervisorDashboardStore.markCheckoffAsComplete()">

                        <b>Mark as complete</b>

                    </div>







                </div>
            </div>
        </div>

        <div v-if="supervisorDashboardStore.showUpdateCheckoffModal" class="modal-overlay">

            <div class="modal-box" style="height: 100%; padding-top: 100px;">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Update Checkoff</b>
                    </div>

                    <div>
                        <i @click="supervisorDashboardStore.closeUpdateCheckoffModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left; background-color: yellow;">



                    <div class="teamList">


                        <table class="table table-hover my-custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Present</th>

                                </tr>
                            </thead>


                            <tbody>



                                <tr v-for="(employee, index) in supervisorDashboardStore.updateEmployeesCheck"
                                    :key="index">



                                    <td>
                                        {{ employee.name }}
                                    </td>


                                    <td @click="supervisorDashboardStore.updatePresentStatus(employee)"
                                        style="display: flex; justify-content: center;">


                                        <div v-if="employee.checked" class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                                checked>
                                        </div>

                                        <div v-else class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                        </div>


                                    </td>


                                </tr>

                            </tbody>




                        </table>



                    </div>

                    <div class="btn btn-success theCheckoffMarkBtn" style="width: 100%;"
                        @click="supervisorDashboardStore.updateAcknowledgementStatus()">

                        <b>Update</b>

                    </div>







                </div>
            </div>
        </div>

        <div v-if="supervisorDashboardStore.showEmployeesListToSelectSignaturesModal" class="modal-overlay">

            <div class="modal-box" style="height: 100%; padding-top: 100px;">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Select Employees To Collect Signatures From</b>
                    </div>

                    <div>
                        <i @click="supervisorDashboardStore.closeEmployeesListToSelectSignaturesModal"
                            class="bi bi-x-lg" style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left; background-color: yellow;">



                    <div class="teamList">


                        <table class="table table-hover my-custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col"></th>

                                </tr>
                            </thead>


                            <tbody>



                                <tr v-for="(employee, index) in supervisorDashboardStore.employeesToCollectSignatures"
                                    :key="index">



                                    <td>
                                        {{ employee.name }}
                                    </td>


                                    <td style="display: flex; justify-content: center;">


                                        <div class="form-check form-switch"
                                            @click="supervisorDashboardStore.addUserToSignatureArray(employee)">
                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                        </div>


                                    </td>


                                </tr>

                            </tbody>




                        </table>



                    </div>

                    <div class="btn btn-success theCheckoffMarkBtn" style="width: 100%;"
                        @click="supervisorDashboardStore.openListOfEmployeesToCollectSignauresFrom">

                        <b>Next</b>

                    </div>







                </div>
            </div>
        </div>


        <div v-if="supervisorDashboardStore.showListOfEmployeesToCollectSignauresFrom" class="modal-overlay">

            <div class="modal-box" style="height: 100%; padding-top: 100px;">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Collect Signatures</b>
                    </div>

                    <div>
                        <i @click="supervisorDashboardStore.closeListOfEmployeesToCollectSignauresFrom"
                            class="bi bi-x-lg" style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left; background-color: yellow;">


                    <!-- {{ supervisorDashboardStore.employeesSelectedForSignatures }} -->

                    <div class="teamList">


                        <table class="table table-hover my-custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col"></th>

                                </tr>
                            </thead>


                            <tbody>



                                <tr v-for="(employee, index) in supervisorDashboardStore.employeesSelectedForSignatures"
                                    :key="index">



                                    <td>
                                        <b>{{ employee.the_user.name }}</b>
                                    </td>


                                    <td style="display: flex; justify-content: center;">


                                        <div v-if="employee.sign_image" class="theImgWrapper"
                                            @click="supervisorDashboardStore.redoSignauture(employee)">

                                            <img :src="employee.sign_image" class="imgSignature">

                                        </div>

                                        <button v-else
                                            @click="supervisorDashboardStore.setCurrentEmployeeToCollectSignatureFrom(employee)"
                                            role="button" class="btn btn-dark btn-sm" style="font-size: 12px;"><b>Add
                                                Signature</b></button>


                                    </td>


                                </tr>

                            </tbody>




                        </table>



                    </div>

                    <div class="btn btn-success theCheckoffMarkBtn" style="width: 100%;"
                        @click="supervisorDashboardStore.signatureFinalSignOff">

                        <b>Final Sign Off</b>

                    </div>







                </div>
            </div>
        </div>


        <div v-if="supervisorDashboardStore.showSignatureBoxModal" class="modal-overlay">

            <div class="modal-box" style="height: 100%; padding-top: 100px;">
                <div class="model-box-header">
                    <div style="font-size: 15px; text-align: left;">
                        <b>{{ supervisorDashboardStore.currentEmployeeToCollectSignatureFrom.the_user.name }}: Add Your
                            Signature</b>
                    </div>

                    <div>
                        <i @click="supervisorDashboardStore.closeSignatureBoxModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">



                    <div class="canvas-container">

                        <VueSignaturePad class="thePad" ref="signaturePad" />


                    </div>

                    <div style="text-align: left; margin-top: 10px; font-size: 14px;">
                        <p>I attended this safety toolbox talk</p>
                    </div>


                    <div class="padbuttons">

                        <button @click="saveDigiSignature" class="signActionBtn">Save</button>

                        <button @click="clearDigiSignature" class="clearActionBtn">Clear</button>

                    </div>







                </div>
            </div>
        </div>

        <div v-if="supervisorDashboardStore.showGroupTalkAcknowledgementsWithSignaturesModal" class="modal-overlay">

            <div class="modal-box" style="height: 100%; padding-top: 100px;">
                <div class="model-box-header">
                    <div style="font-size: 15px; text-align: left;">
                        <b>Signatures</b>
                    </div>

                    <div>
                        <i @click="supervisorDashboardStore.closeGroupTalkAcknowledgementsWithSignaturesModal"
                            class="bi bi-x-lg" style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">



                    <div class="teamList">


                        <table class="table table-hover my-custom-table">

                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col"></th>

                                </tr>
                            </thead>


                            <tbody>



                                <tr v-for="(ack, index) in supervisorDashboardStore.grouptTalkAcknowledgementsWithSignatures"
                                    :key="index">



                                    <td>
                                        <div v-if="ack.user">
                                            <b>{{ ack.user.name }}</b>
                                        </div>

                                    </td>


                                    <td style="display: flex; justify-content: center;">




                                        <div v-if="ack.signature_img_url" class="theImgWrapper"
                                            @click="supervisorDashboardStore.redoAckSignauture(ack)">

                                            <img :src="ack.signature_img_url" class="imgSignature">

                                        </div>




                                    </td>


                                </tr>

                            </tbody>




                        </table>



                    </div>




                </div>
            </div>
        </div>


        <div v-if="supervisorDashboardStore.showRedoAckSignatureModal" class="modal-overlay">

            <div class="modal-box" style="height: 100%; padding-top: 100px;">
                <div class="model-box-header">

                    <div style="font-size: 15px; text-align: left;"
                        v-if="supervisorDashboardStore.currentGroupAck && supervisorDashboardStore.currentGroupAck.user">
                        <b>{{ supervisorDashboardStore.currentGroupAck.user.name }}: Redo Your
                            Signature</b>
                    </div>

                    <div>
                        <i @click="supervisorDashboardStore.closeRedoAckSignatureModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">



                    <div class="canvas-container">

                        <VueSignaturePad class="thePad" ref="signaturePad" />


                    </div>

                    <div style="text-align: left; margin-top: 10px; font-size: 14px;">
                        <p>I attended this safety toolbox talk</p>
                    </div>


                    <div class="padbuttons" v-if="supervisorDashboardStore.isLoading">

                        <button role="button" class="signActionBtn">Loading...</button>


                    </div>

                    <div class="padbuttons" v-else>

                        <button @click="saveRedoDigiSignature" class="signActionBtn">Save</button>

                        <button @click="clearDigiSignature" class="clearActionBtn">Clear</button>

                    </div>









                </div>
            </div>
        </div>


        <div v-if="supervisorDashboardStore.showTakeImageOfSheetModal" class="modal-overlay">

            <div class="modal-box" style="height: 100%; padding-top: 100px;">
                <div class="model-box-header">

                    <div style="font-size: 15px; text-align: left;">
                        <b>Take A Photo Of Sign-In Sheet</b>
                    </div>

                    <div>
                        <i @click="supervisorDashboardStore.closeTakeImageOfSheetModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left; height: 500px; width: 100%;">

                    <div v-if="capturedImage">

                        <div class="theCameraBtnArea">

                            <button v-if="supervisorDashboardStore.isLoading"
                                class="btn btn-dark"><b>Uploading</b></button>

                            <button v-else @click="sendToDBtOuploadPhotoOfSignSheet" class="btn btn-dark"><b>Use This
                                    Photo</b></button>

                            <button @click="retakePhoto" class="btn btn-danger" style="font-size: 12px;"><b><i
                                        class="bi bi-arrow-clockwise"></i> Retake</b></button>

                        </div>

                        <div class="theImageBox">


                            <img class="theRenderedImg" v-if="capturedImage" :src="capturedImage" />

                            <!-- <img class="theRenderedImg"
                                src="https://www.workyard.com/wp-content/uploads/2023/06/Hand-Safety-Toolbox-Talk-scaled.jpg"
                                alt="" srcset=""> -->

                        </div>

                    </div>

                    <div v-else>

                        <div class="theOpenCameraBtnArea">

                            <input type="file" accept="image/*" @change="onCapture" ref="fileInput"
                                style="display: none;">

                            <button class="btn theCamBtn" @click="openCamera">Open Camera</button>

                        </div>


                    </div>





                    <!-- <div class="cameraButtonBox">


                        <div class="theCameraBtnArea">







                            <input type="file" accept="image/*" capture="environment" @change="onCapture"
                                ref="fileInput" style="display: none;">

                            <img v-if="capturedImage" :src="capturedImage" style="width: 200px; height: 200px;" />



                            <button class="btn theCamBtn" @click="openCamera">Open Camera</button>






                        </div>


                    </div> -->




                </div>
            </div>
        </div>

        <div v-if="supervisorDashboardStore.showSigninSheetPreviewModal" class="modal-overlay">

            <div class="modal-box" style="height: 100%; padding-top: 100px;">
                <div class="model-box-header">

                    <div style="font-size: 15px; text-align: left;">
                        <b>Sign-In Sheet</b>
                    </div>

                    <div>
                        <i @click="supervisorDashboardStore.closeSigninSheetPreviewModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left; height: 500px; width: 100%;">



                    <div class="theCameraBtnArea">

                        <button @click="supervisorDashboardStore.downloadImageFile"
                            class="btn btn-dark"><b>Download</b></button>


                    </div>

                    <div class="theImageBox" v-if="supervisorDashboardStore.groupTalkPost">


                        <img class="theRenderedImg"
                            :src="supervisorDashboardStore.groupTalkPost.sign_in_sheet_img_url" />


                    </div>







                </div>
            </div>
        </div>


    </div>
</template>

<script setup>


import { ref, onMounted, onUnmounted, defineProps } from 'vue';
import SupervisorMainSideBar from '@/components/SupervisorMainSideBar.vue';
import SupervisorMobileTopNav from '@/components/SupervisorMobileTopNav.vue';
import SupervisorMobileBottomNav from '@/components/SupervisorMobileBottomNav.vue';
import TopBarVue from "@/components/TopBar.vue";
import { useSupervisorDashboardStore } from '@/stores/SupervisorDashboardStore';
import VuePdfEmbed from 'vue-pdf-embed'


const supervisorDashboardStore = useSupervisorDashboardStore();


const props = defineProps({
    uuid: String
})

const windowWidth = ref(window.innerWidth);
const mobileWidthThreshold = 1230; // Example threshold for mobile width, adjust as needed

const onResize = () => {
    windowWidth.value = window.innerWidth;
};



const signaturePad = ref(null);


const saveDigiSignature = () => {

    const { data } = signaturePad.value.saveSignature();

    supervisorDashboardStore.setSignatureImage(data)

};

const saveRedoDigiSignature = () => {

    const { data } = signaturePad.value.saveSignature();

    supervisorDashboardStore.setRedoAckSignatureImage(data)

};


const clearDigiSignature = () => {
    signaturePad.value.clearSignature(); // Clear the signature pad
};






/* eslint-disable */
const fileInput = ref(null);
const capturedImage = ref(null);

const openCamera = () => {
    if (fileInput.value) {
        fileInput.value.click();
    }
};

const onCapture = (event) => {

    const file = event.target.files[0];

    if (file) {

        const reader = new FileReader();

        reader.onload = (e) => {

            capturedImage.value = e.target.result;

            // console.log('Captured image as base64:', e.target.result);

        };

        reader.readAsDataURL(file);
    }
};


const retakePhoto = () => {
    capturedImage.value = null
}

const sendToDBtOuploadPhotoOfSignSheet = () => {

    supervisorDashboardStore.finalMarkUploadSignInSheetAsComplete(capturedImage.value)

    // console.log(capturedImage.value)
}



onMounted(() => {
    window.addEventListener('resize', onResize);
    supervisorDashboardStore.fetchGroupTalkPost(props.uuid)

});

onUnmounted(() => {

    window.removeEventListener('resize', onResize);
    supervisorDashboardStore.resetArraysForDigitalSignatures();

});



</script>

<style scoped>
.mainWrapper {
    /* background-color: rgb(118, 183, 240); */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    width: 83%;
    display: flex;
    flex-direction: column;
    /* background-color: bisque; */
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.theHeaderWrapper {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}


.theBar {
    max-width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.contentWrapper {
    display: flex;

    justify-content: center;
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}


.rowWrapper {
    display: flex;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* background-color: bisque; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    cursor: pointer;
}

.rowWrapper:hover {
    background-color: rgb(243, 245, 244);
}

.talkTitle {
    /* background-color: antiquewhite; */
    text-align: left;
    font-weight: bold;
}

.bottomPart {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: 100%;
    /* background-color: rgb(215, 215, 230); */
    margin-top: 10px;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}


.signedPart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
    margin-right: 20px;
}

.datePart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
}


.talkBox {
    width: 900px;

    /* background-color: antiquewhite; */
}


.textBox {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.videoBox {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.pdfBox {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.headerBox {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #e9faf3;
    font-size: 16px;
    font-weight: bold;
    padding: 20px;
    text-align: left;
    font-size: 19px;
    font-weight: bold;
}

.theInput {

    width: 100%;

}

.formBox {
    padding: 10px;
    margin-bottom: 30px;
}

.uploadBox {

    padding: 30px;
    margin-bottom: 30px;

}

.thePic {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.textContent {
    padding: 20px;
    text-align: left;
    font-size: 17px;

}

.videoContent {
    padding: 10px;
    text-align: left;
    font-size: 17px;

}




.pdfContent {
    text-align: left;
    font-size: 17px;
    width: 100%;
    height: auto;
    overflow-y: auto;
}

/* Adjust the height based on the viewport width */
@media (max-width: 1230px) {
    .pdfContent {
        height: 400px;
        /* Example height for smaller screens */
    }
}

/* Larger screens, can have a larger fixed height or remain auto */
@media (min-width: 1231px) {
    .pdfContent {
        height: 700px;
        /* Example height for larger screens */
    }
}

::v-deep .htmlHtml img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-bottom: 15px;
    margin-top: 15px;
}

/* Media query for larger screens */
@media (min-width: 1231px) {
    ::v-deep .htmlHtml img {
        max-width: 500px;
    }
}


.normalVideoWrapper {
    width: 100%;
    height: 100%;
}

.yTvideoWrapper {
    width: 100%;
    height: 500px;
}


/* When the view port min width is 500 change height to 500px */
@media (max-width: 500px) {

    .yTvideoWrapper {
        height: 300px;

    }

}


.achnowledgeWrapper {
    display: flex;
    justify-content: center;
    /* background-color: #6ddaad; */
    width: 100%;
}

.achnowledgeBox {
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 900px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    font-weight: bold;
    font-size: 16px;
    background-color: #f8f9fa;
}

.achnowledgeBoxSuccess {
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 900px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    font-weight: bold;
    font-size: 16px;
    background-color: #f8f9fa;
}



.quizBox {

    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 900px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-size: 16px;
    padding: 10px;
    background-color: #f9f9f9;

}

.questionBox {
    max-width: 900px;
    padding: 20px;
    font-weight: bolder;
    font-size: 17px;
    /* background-color: #6b9fd2; */
    text-align: left;
}

.answerItem {
    padding: 10px;
    font-size: 16px;
    text-align: left;

}


.form-check {
    display: flex;
    flex-direction: row;
}


.form-check-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid rgb(179, 179, 179);
    cursor: pointer;
    margin-right: 10px;
}

.form-check-input:checked {
    background-color: green;
}


.quizNav {

    display: flex;
    justify-content: space-between;
    /* background-color: #e9faf3; */
    width: 100%;
    padding: 10px;
    margin-top: 10px;

}


.quizResults {
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 900px;

    font-size: 16px;
    padding: 10px;
}

.talkDone {
    display: flex;
    align-items: center;
    /* background-color: greenyellow; */

}

.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.theTitle {
    font-size: 15px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.optionItemWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: gray; */
}

.optionItem {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin: 10px;
    border-radius: 5px;
    padding: 20px;

    cursor: pointer;

}

.optionItemSelected {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin: 10px;
    border-radius: 5px;
    padding: 20px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    cursor: pointer;
}

.theItemHeader {
    text-align: center;
    font-weight: bold;
}

.theItemBody {
    text-align: center;
    font-size: 15px;
}

.teamList {
    max-height: 450px;
    background-color: rgb(247, 247, 247);
    overflow-y: auto;
    padding: 5px;
    border-radius: 5px;
}


.form-check-input:checked {
    cursor: pointer;
    background-color: #198754;
    /* Bootstrap's green color */
    border-color: #198754;
    /* Bootstrap's green color */
}

.form-check-input:focus {
    cursor: pointer;
    border-color: #198754;
    /* Bootstrap's green color */
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
    /* A lighter green shadow */
}

.theCheckoffMarkBtn {
    border-radius: 0px;
}


.canvas-container {
    width: 100%;
    height: 300px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.thePad {
    width: 100%;
    height: 100px;
    background-color: white;
    border: 2px solid rgb(219, 219, 219);
    border-radius: 5px;
}


.signActionBtn {
    width: 50%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    color: rgb(3, 2, 2);
    border-radius: 10px;
    padding: 6px;
    margin-right: 10px;
    border: none;
    font-weight: bold;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

}

.clearActionBtn {
    width: 50%;
    background: white;
    color: rgb(3, 2, 2);
    border-radius: 10px;
    padding: 6px;
    border: none;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.signImgCont {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;

}

.imgSignBox {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    cursor: pointer;
    /* border: 1px solid black; */
}

.padbuttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 3px;
    /* gap: 15px; */
}

.theImgWrapper {
    width: 70px;
    height: 50px;
    overflow: hidden;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 5px;
    cursor: pointer;
}


.imgSignature {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;

}

.cameraButtonBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    /* background-color: yellow; */
}

.theCameraBtnArea {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
}

.theCamBtn {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    font-weight: bold;
    height: 40px;
    width: 100%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.renderImgBox {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    height: 600px;
    width: 100%;
    background-color: #d5d9d9
}

.buttonsArea {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    /* background-color: green; */
}

.theImageBox {
    width: 100%;
    /* background-color: rgb(247, 67, 67) */
}

.theRenderedImg {
    width: 100%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

}

.theOpenCameraBtnArea {
    display: flex;
    /* background-color: yellow; */
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;

}
</style>